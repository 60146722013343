import { render, staticRenderFns } from "./NameBlock.vue?vue&type=template&id=4af21fcc&shadow"
import script from "./NameBlock.vue?vue&type=script&lang=js&shadow"
export * from "./NameBlock.vue?vue&type=script&lang=js&shadow"
function injectStyles (context) {
  
  var style0 = require("./NameBlock.vue?vue&type=style&index=0&lang=css&shadow")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

component.options.__file = "NameBlock.vue"
export default component.exports